* {
  margin: 0;
  padding: 0;
}

:root {
  --kcc-green: #016623;
  --kcc-light-green: #029636;
  --hph-red: #eb0013;
  --hph-light-red: #fe405d;
  --hph-white: #ffffff;
}

body,
html,
#react-app {
  display: flex;
  height: 100%;
  width: 100%;
  flex: 1;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
    ArialMTBlack, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol';
}

input:focus,
textarea:focus {
  outline-style: none;
  box-shadow: none;
  outline: none;
  border-color: transparent;
}

.green-button {
  border-radius: 6px;
  background: linear-gradient(
    to right,
    var(--kcc-light-green) 50%,
    var(--kcc-green) 50%
  );
  background-size: 200% 100%;
  background-position: right bottom;
  transition: all 0.3s ease-out;
}

.green-button:hover {
  background-position: left bottom;
}

.red-button {
  border-radius: 6px;
  background: linear-gradient(
    to right,
    var(--hph-light-red) 50%,
    var(--hph-red) 50%
  );
  background-size: 200% 100%;
  background-position: right bottom;
  transition: all 0.3s ease-out;
}

.red-button:hover {
  background-position: left bottom;
}

.red-button:disabled {
  background-color: antiquewhite;
}

.white-button {
  border-radius: 6px;
  background: linear-gradient(
    to right,
    var(--hph-white) 50%,
    var(--hph-white) 50%
  );
  background-size: 200% 100%;
  background-position: right bottom;
  transition: all 0.3s ease-out;
}

.white-button:hover {
  background-position: left bottom;
}

.slidebar-item {
  background: linear-gradient(
    to right,
    var(--hph-light-red) 50%,
    transparent 50%
  );
  background-size: 200% 100%;
  background-position: right bottom;
  transition: all 0.3s ease-out;
}

.dropdown {
  padding: 10px;
}
.slidebar-item:hover {
  background-position: left bottom;
}

div[role='button'] * {
  /* pointer-events: none; */
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                     supported by Chrome and Opera */
}

.booking_type_item:hover {
  background-position: left bottom;
}
